var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vendor-type my-3",
      attrs: { id: "vendor-" + _vm.data.block }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-center align-center pt-1 card1"
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center pb-2",
              style: { borderBottom: "1px solid " + _vm.primaryColor }
            },
            [
              _c("Icon", {
                staticClass: "primary--text",
                attrs: {
                  data: _vm.icons[_vm.data.data.navbar[0]["class"]],
                  width: "24",
                  height: "24"
                }
              }),
              _c(
                "div",
                { staticClass: "span title--text font-weight-bold ml-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.data.data.navbar[0].game_type ||
                          _vm.data.data.navbar[0].class
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "Swiper",
        {
          ref: "gameTypeSlide",
          staticClass: "mySwiper card1",
          attrs: { options: _vm.swiperOption }
        },
        _vm._l(_vm.data.data.navbar[0].items, function(vendorType, index) {
          return _c(
            "SwiperSlide",
            {
              key: vendorType._id,
              staticClass:
                "py-2 d-flex justify-center subtitle-2 cursor-pointer",
              class:
                _vm.activeVendorTypeIndex === index
                  ? "title--text"
                  : "textfield--text",
              style:
                _vm.activeVendorTypeIndex === index ? _vm.activeTabStyle : ""
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-100 text-center",
                  on: {
                    click: function($event) {
                      return _vm.setVendorActiveIndex(index)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(vendorType.title) + " ")]
              )
            ]
          )
        }),
        1
      ),
      _c(
        "Swiper",
        { ref: "vendorSlide", on: { slideChange: _vm.slideVendorType } },
        _vm._l(_vm.data.data.navbar[0].items, function(vendorType) {
          return _c(
            "SwiperSlide",
            { key: vendorType._id, staticClass: "textfield--text subtitle-2" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 px-3 py-2" },
                _vm._l(vendorType.games, function(hotGame) {
                  return _c(
                    "v-col",
                    {
                      key: hotGame._id,
                      staticClass: "px-0 pb-1 cursor-pointer",
                      attrs: { cols: "6" }
                    },
                    [
                      _c("div", { staticClass: "px-1" }, [
                        _c(
                          "div",
                          {
                            staticClass: "rounded overflow-hidden",
                            class:
                              hotGame.status === "0" ? "maintenanceBox" : ""
                          },
                          [
                            hotGame.status == "0"
                              ? _c("maintenance")
                              : _vm._e(),
                            _c("v-img", {
                              staticClass: "rounded",
                              attrs: {
                                src: _vm.baseImgUrl + hotGame.game.image
                              },
                              on: {
                                click: function($event) {
                                  return _vm.launchGame(hotGame.game_code)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "px-2 pt-1 title--text caption" },
                          [_vm._v(" " + _vm._s(hotGame.localized_name) + " ")]
                        )
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }